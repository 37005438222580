/* Add this to your CSS file or style tag */
.datePickerInput {
  border-radius: 5px;
  border-width: 2px;
  border-color: gray.300;
  padding: 8px;
  padding-left: 15px;
  padding-right: 60px;
}

.datePickerBox {
  position: absolute;
  top: -8px;
  left: 8px;
  pointer-events: none;
  color: green;
  font-size: sm;
  background: white;
  padding-left: 1;
  padding-right: 1;
}
